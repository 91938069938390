import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import App from './pages/App';
import Team from './pages/Team'
import Study from './pages/Study';
import Theory from './pages/Theory';
import ParticipantSurvey from './pages/ParticipantSurvey';
import MgAdl from './pages/MgAdl';
import Hads from './pages/Hads';
import CGU from './pages/CGU';
import MentionsLegales from './pages/MentionsLegales';
import reportWebVitals from './reportWebVitals';

// import Help from './components/Help';

import { createInstance, MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';

const instance = createInstance({
  urlBase: 'https://matomo.skezi.eu/',
  siteId: 8,
  linkTracking: false,
});

const AppRendering = () => {
  const { enableLinkTracking } = useMatomo()
  enableLinkTracking()

  return (
    <>
      <Router>
        <MatomoProvider value={instance}>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/team" element={<Team />} />
            <Route path="/theory" element={<Theory />} />
            <Route path="/study" element={<Study />} />
            {/* <Route path="/survey" element={<ParticipantSurvey />}/>
            <Route path="/mgadl" element={<MgAdl />} />
            <Route path="/hads" element={<Hads />} />*/}
            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="/cgu" element={<CGU />} />
          </Routes>
        </MatomoProvider>
      </Router>
      {/* <Help /> */}
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppRendering />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
