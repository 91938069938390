/* eslint-disable react-hooks/exhaustive-deps */
import Footer from '../components/Footer';
import Header from '../components/Header';

import WoodenSpoon from '../image/wooden-spoon.png';
import SilverSpoon from '../image/silver-spoon.png';

import { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const MentionsLegales = () => {

  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ documentTitle: 'Page des mentions légales' });
  }, []);

  return (
    <>
      <Header />
      <div className="container mx-auto my-20 px-5 font-arimo min-h-[60vh]">
        <p className="relative mb-20">
          <img src={WoodenSpoon} alt="Cuillère en bois" className="block w-24 sm:w-32 absolute -top-[53px] -left-36 -z-10" />
          Conformément aux dispositions de l’article 6 III 1° de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, nous vous informons que : <br /><br />
          L’Application est éditée par SKEZI, inscrite au répertoire SIRET sous le numéro 89222360300010, dont le siège social est situé à ABS - SKEZI, Centre Bonlieu, 1 rue Jean Jaurès, 74000 Annecy. <br /><br />
          La plateforme SPOON est la propriété de l’entreprise UCB Pharma SA, inscrite au répertoire SIREN sous le numéro 562 079 046, dont le siège social est situé au 420 rue Etienne d’Orves, 92700 Colombes. Le directeur de la publication est : <br /><br />
          - Anne CROCHARD, Responsable Real World Evidence  
        </p>
        <p className="relative">
          <img src={SilverSpoon} alt="Cuillère en argent" className="block w-24 sm:w-32 absolute -top-[53px] -left-36 -z-10" />
          La plateforme SPOON est réalisée par : <br /><br />
          La Société SKEZI, Adresse ABS - SKEZI, Centre Bonlieu, 1 rue Jean Jaurès, 74000 Annecy (France) <br /><br />
          L’hébergement est assuré par OVH, 2 rue Kellermann – 59100 Roubaix – France Numéro de téléphone de l’hébergeur : 1007 (service client) <br /><br />
        </p>
      </div>
      <Footer />
    </>
  );
}

export default MentionsLegales;