/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { DownloadOutlined, SaveOutlined, MailOutlined } from '@ant-design/icons';
import { Model, StylesManager, Survey, surveyLocalization } from 'survey-react';
import { Modal, Form, Input, Button, Typography, Divider, notification, Alert, Spin } from 'antd';
import ResponseService from '../services/response';
import womenImage from '../image/women_illustration.svg';
import logoSpoon from '../image/logo.png';
import { SurveyPDF, SurveyHelper } from "survey-pdf";
import DeviceDetector from 'device-detector-js';
import Footer from '../components/Footer';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import './customCssSurvey.css';
import { Link } from 'react-router-dom';

StylesManager.applyTheme('defaultV2');
SurveyHelper.HTML_TO_IMAGE_QUALITY = 4.0;

const surveyCss = {
    progressTextInBar: 'progressText',
    progressTextUnderBar: 'sv-hidden'
};

const { Title } = Typography;

function ParticipantSurvey() {
    const [surveyData, setSurveyData] = useState();
    const [saveForm] = Form.useForm();
    const saveButton = useRef(null);
    const loadButton = useRef(null);
    const [loadForm] = Form.useForm();
    const [notFound, setNotFound] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [code, setCode] = useState();
    const [lastQuestionAnswer, setLastQuestionAnswer] = useState('');
    const [responsesSaved, setResponsesSaved] = useState();

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({ documentTitle: 'Page de questionnaire' });

        const deviceDetector = new DeviceDetector();
        const device = deviceDetector.parse(navigator.userAgent);

        async function initSurvey() {
            const responseService = new ResponseService();
            const surveyJSON = await responseService.loadSurvey(device.device?.type);

            setSurveyData(surveyJSON);
        }
        initSurvey();
    }, []);

    const onComplete = useCallback(async (survey) => {
        survey.stopTimer();
        const responseService = new ResponseService();

        await responseService.addResponse({
            data: survey.data,
            ...code ? { code: code } : {},
            timeSpent: survey.timeSpent,
            state: 'COMPLETED'
        });
    }, [code]);

    const onFocusInQuestion = useCallback((_, options) => {
        if (options.question.name !== 'C_11')
            setLastQuestionAnswer(options.question.name);
    }, []);

    const survey = useMemo(() => {
        const survey = new Model(surveyData);

        survey.stopTimer();
        survey.startTimer();

        survey.onComplete.add(onComplete);
        survey.onFocusInQuestion.add(onFocusInQuestion);

        if (responsesSaved) {
            survey.firstPageIsStarted = false;
            survey.data = responsesSaved.json;
            survey.currentPage = responsesSaved.page;
        }

        return survey;

    }, [surveyData, responsesSaved, code]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const sendErrorNotification = (message) => {
        return notification.error({
            placement: 'top',
            message: 'Erreur',
            description: message,
        });
    }
    
    const onSave = async (data) => {
        if (!survey.data.inc_nai)
            return sendErrorNotification('Vous devez commencer à remplir le questionnaire pour permettre l\'enregistrement des réponses.');

        if (survey.hasCookie)
            return sendErrorNotification('Vous avez terminé la complétion du formulaire, vos réponses ont déjà été enregistrées.');

        if (!Object.keys(survey.data).length)
            return sendErrorNotification('Vous devez commencer à remplir le questionnaire pour permettre l\'enregistrement des réponses.');

        const responseService = new ResponseService();

        if (saveButton && saveButton.current) {
            saveButton.current.setAttribute("disabled", 'true');
        }

        try {
            survey.stopTimer();

            const returnCode = await responseService.addPartialResponse({
                email: data.email,
                data: survey.data,
                ...code ? { code: code } : {},
                timeSpent: survey.timeSpent,
                lastQuestionAnswer
            });

            setCode(returnCode);
            setResponsesSaved({
                json: survey.data,
                page: survey.getQuestionByName(lastQuestionAnswer).page.name
            });
            notification.success({
                placement: 'top',
                message: `Succès !`,
                description: 'Un mail vous a été envoyé avec un code de sauvegarde !',
            });
            setIsModalVisible(false);
            saveForm.resetFields();
        } catch (error) { console.log(error) }

        if (saveButton && saveButton.current) {
            saveButton.current.removeAttribute('disabled');
        }
    };

    const onLoad = async (data) => {
        const responseService = new ResponseService();

        if (loadButton && loadButton.current) {
            loadButton.current.setAttribute("disabled", 'true');
        }

        try {
            const deviceDetector = new DeviceDetector();
            const device = deviceDetector.parse(navigator.userAgent);

            const result = await responseService.loadResponses(data.code, device.device?.type);
            setCode(data.code);
            setResponsesSaved(result);
            setNotFound(false);
            setIsModalVisible(false);
            loadForm.resetFields();

            notification.success({
                placement: 'top',
                message: `Succès !`,
                description: 'Vos réponses ont bien été récupérées',
            });
        } catch (error) {
            setNotFound(true);
        }

        if (loadButton && loadButton.current) {
            loadButton.current.setAttribute("disabled", 'false');
        }
    };

    const onDownloadPDF = () => {
        const surveyPdf = new SurveyPDF(surveyData, { applyImageFit: true });
        surveyPdf.haveCommercialLicense = true;
        surveyPdf.data = survey.data;
        surveyPdf.save();
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className={`${surveyData ? 'bg-[#F8F8F8]' : ''} font-arimo`}>
            <div style={{ boxShadow: '0 4px 4px rgba(0, 0, 0, .1)' }} className="h-28 sm:h-40 bg-[#FFEAD6] overflow-hidden flex items-center justify-between px-5 sm:px-10 relative">
                <Link to="/">
                    <img src={logoSpoon} alt="Logo Spoon" className="block h-16 sm:h-24"></img>
                </Link>
                <img src={womenImage} alt="Illustration personnage" className="block h-28 sm:h-40 md:h-56" />
            </div>
            <br />
            <br />
            <br />
            {
                surveyData ? (
                    <>
                        <div>
                            <div>
                                {survey &&
                                    <>
                                        <div className="survey -mt-[50px] md:-mt-[70px]">
                                            <Survey css={surveyCss} model={survey} />
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="flex flex-col flex-grow-0 items-end ml-8 sm:flex-row sm:justify-end sm:mr-16">
                                <button className="save-data" onClick={showModal}>
                                    <span className="text-on-hover">Sauvegarder / récupérer mon formulaire</span>
                                    <SaveOutlined style={{ fontSize: '35px' }} />
                                </button>
                                <button className="save-data download" onClick={onDownloadPDF}>
                                    <span className="text-on-hover">Télécharger mon formulaire</span>
                                    <DownloadOutlined style={{ fontSize: '35px' }} />
                                </button>
                            </div>
                        </div>
                        <Footer />
                    </>
                ) : (
                    <div className="mt-20 text-center">
                        <Spin size='large' />
                    </div>
                )
            }
            <Modal
                title="Sauvegarde"
                visible={isModalVisible}
                okText="Envoyer"
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Annuler
                    </Button>
                ]}
            >
                <p className="text-red-400 mb-7">* Champ obligatoire pour continuer la sauvegarde</p>
                <Title level={5}>Continuer le questionnaire plus tard</Title>
                <p>Vous souhaitez continuer le questionnaire plus tard, merci d'indiquer votre adresse mail afin que nous puissions vous envoyer un code unique.</p>
                <p>Ce code unique sera à rentrer lors de votre prochaine visite.</p>
                <Form form={saveForm} layout="vertical" onFinish={onSave}>
                    <Form.Item
                        name="email"
                        label="Votre adresse e-mail"
                        rules={[

                            {
                                type: 'email',
                                message: 'L\'adresse e-mail est pas valide'
                            },
                            {
                                required: true,
                                message: 'Merci de rentrer votre adresse e-mail'
                            }
                        ]}
                    >
                        <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Adresse e-mail" />
                    </Form.Item>
                    <Button ref={saveButton} className="login-form-button" type="primary" htmlType="submit">
                        Sauvegarder
                    </Button>
                </Form>
                <Divider />
                <Title level={5}>Récupérer une sauvegarde</Title>
                <p>Vous avez déjà fait la demande de continuer plus tard et souhaitez récupérer vos données, indiquez le code de sauvegarde que vous avez reçu par mail ci-dessous.</p>
                <Form form={loadForm} layout="vertical" onFinish={onLoad}>
                    <Form.Item>
                        <Form.Item
                            name="code"
                            label="Votre code de sauvegarde"
                            rules={[
                                {
                                    required: true,
                                    message: 'Merci de rentrer votre code de sauvegarde'
                                }
                            ]}
                        >
                            <Input prefix={<SaveOutlined className="site-form-item-icon" />} placeholder="Code" />
                        </Form.Item>
                        <Button className="login-form-button" type="primary" htmlType="submit">
                            Récupérer
                        </Button>
                    </Form.Item>
                </Form>
                {notFound &&
                    <Alert message="Le code n'a pas été trouvé" type="error" />
                }
            </Modal>
        </div>
    )
}

export default ParticipantSurvey