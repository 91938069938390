import React from 'react';
import LogoUCB from '../image/logo-ucb.png';
import LogoSKEZI from '../image/logo-skezi.png';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="flex flex-col md:flex-row md:justify-between md:px-4 md:pt-5 md:bg-auto md:bg-repeat-x md:bg-background-footer md:h-20 font-arimo">
            <div className="h-20 w-52 mx-auto lg:h-12 lg:w-48 lg:my-auto lg:ml-0 md:h-12 md:w-48 md:my-auto">
                <a href='https://www.ucb-france.fr/'><img src={LogoUCB} className="w-[100%]" alt="logo-ucb"></img></a>
            </div>
            <div className="flex flex-col items-center justify-center space-y-5 font-bold mt-5 mb-12 text-lg md:flex-row md:space-y-0 md:space-x-5 md:mt-0 md:mb-2 lg:space-x-28 xl:space-x-44">
                <Link to='/mentions-legales' className="text-[#22577A]">
                    Mentions légales
                </Link>
                <Link to='/cgu' className="text-[#22577A]">
                    CGU
                </Link>
            </div>
            <div className="h-20 w-52 mx-auto lg:h-12 lg:w-64 lg:my-auto lg:mr-0 md:h-12 md:w-64 md:my-auto">
                <a href="https://skezi.eu/"><img src={LogoSKEZI} alt="logo-skezi" className="lg:pt-2 md:pt-2 w-[100%]"></img></a>
            </div>
        </div>
    )
}

export default Footer