/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import surveyData from '../hads.json';
import { Model, StylesManager, Survey } from 'survey-react';

import './customCssSurvey.css';
import 'antd/dist/antd.min.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/modal/style/index.css';

StylesManager.applyTheme('defaultV2')

function Hads() {
    const [survey, setSurvey] = useState();

    useEffect(() => {
        setSurvey(new Model(surveyData));
    }, [surveyData]);

    return (
        <>
            <div className="min-h-screen bg-[#FFEAD6] relative font-arimo">
                <div className="flex justify-between lg:flex lg:justify-between md:flex md:justify-between">
                    <div className='pt-2.5 lg:pt-2.5 md:pt-2.5'>
                        <div className="flex h-12 w-[130px] rounded-br-[35px] lg:flex lg:w-[480px] lg:h-20 lg:rounded-br-[85px] bg-emerald-800 md:w-72 md:flex md:h-20 md:rounded-br-[85px]"></div>
                    </div>

                </div>
                {survey &&
                    <div className="survey w-screen">
                        <Survey model={survey} />
                    </div>
                }
            </div>
        </>
    )
}

export default Hads;