import React from 'react';
import Logo from '../image/logo.png';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <header style={{ boxShadow: '0 4px 4px rgba(0, 0, 0, .1)' }} className="h-36 flex items-center justify-between bg-[#FFEAD6] font-bold font-arimo">
            <Link to="/">
                <img src={Logo} alt="Logo Spoon" className="block w-24 ml-5 md:w-32 md:ml-10 lg:w-40 lg:ml-14" />
            </Link>
            <div className="flex flex-col space-y-3 pr-2 items-center justify-center sm:flex-row sm:space-y-0 sm:space-x-5 md:space-x-10 lg:space-x-20">
                <Link to="/study" className="uppercase text-base lg:text-xl text-emerald-800 hover:underline hover:text-emerald-600 focus:underline focus:text-emerald-600">
                    L'étude
                </Link>
                <Link to="/theory" className="uppercase text-base lg:text-xl text-emerald-800 hover:underline hover:text-emerald-600 focus:underline focus:text-emerald-600">
                    La théorie Spoon
                </Link>
            </div>
        </header>
    )
}

export default Header