import React from 'react';
import Header from '../components/Header'
import BeginStudy from '../components/BeginStudy'
import Footer from '../components/Footer'

function Team() {
    return (
        <div>
            <Header />
            <BeginStudy />
            <Footer />
        </div>
    )
}

export default Team