/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LittleBanner from '../image/little-banner.png';
import EnlightSpoon from '../image/enlight-spoon.png';
import ExplainTheory from '../image/explain-theory.png';
import SpoonFull from '../image/spoon-full.png';
import SpoonHalf from '../image/spoon-half.png';
import { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function Theory() {

    const { trackPageView } = useMatomo();
  
    useEffect(() => {
      trackPageView({ documentTitle: 'Page théorie Spoon' });
    }, []);

    return (
        <div className="min-h-full min-w-full">
            <Header />
            <div className="flex mt-12 mb-4 lg:flex lg:mt-20 font-arimo md:flex md:mt-10">
                <img src={LittleBanner} alt="silver-spoon" className="h-2 w-10 my-auto lg:my-auto lg:w-24 md:my-auto md:h-[8px]"></img>
                <span className="pl-2 pr-4 text-[18px] lg:pl-[20px] md:pl-[20px] font-bold lg:text-[24px] text-emerald-800 md:text-xl">Comprendre la fatigabilité selon Christine Miserandino </span>
            </div>
            <section className="mx-10 lg:mx-20 font-arimo">
                <div className="flex flex-col mb-[30px]">
                    <div className="mb-16 lg:mx-20 lg:my-[20px] md:m-0">
                        <p className="text-justify text-[15px] mb-5 lg:mb-[20px] lg:pt-5 lg:text-[20px] md:mb-[20px] md:text-[16px] leading-6 md:pt-14"><span className="text-rose-500 font-bold lg:text-rose-500 md:text-rose-500 md:font-bold">"La théorie de la cuillère"</span>, est une histoire personnelle de <span className="lg:text-rose-500 md:text-rose-500 md:font-bold">Christine Miserandino</span>.<br />
                            Elle a été popularisée parmi de nombreuses personnes confrontées à une maladie chronique car elle décrit cette idée d'énergie limitée, en utilisant les "cuillères" comme unité d'énergie. L’auteure explique qu’une personne malade (ou ayant un handicap) dispose d’une réserve d’énergie moins importante que les autres.<br />
                            Elle symbolise cette énergie par des cuillères que le patient peut dépenser dans la journée.</p>
                    </div>
                    <div className="lg:mx-20 lg:flex lg:justify-between md:m-0 md:flex md:justify-between">
                        <img src={ExplainTheory} alt="" className="block mx-auto mb-10 max-w-md w-full md:w-[50%] md:h-[50%] lg:my-auto md:my-auto md:mr-[30px]"></img>
                        <div className="lg:flex lg:flex-col lg:ml-10 lg:my-auto">
                            <p className="text-justify text-[15px] mb-5 lg:mb-[20px] lg:text-[20px] md:mb-[20px] md:text-[16px] lg:leading-6 md:leading-6">Christine Miserandino vit avec un lupus, une maladie auto-immune chronique qui pousse le système immunitaire à attaquer les cellules saines de l'organisme. Elle doit adapter sa vie quotidienne et ses activités à sa maladie.<br />En 2003, elle écrit un essai, la Théorie des cuillères sur son blog <span className="italic">"But You don't look sick"</span> (Mais vous n'avez même pas l'air malade), qui a pour but d’expliquer le quotidien d’une personne malade ou porteuse de handicap.</p>
                            <p className="text-justify text-[15px] lg:mb-[20px] lg:text-[20px] md:mb-[20px] md:text-[16px] lg:leading-6 md:leading-6">Elle explique qu'il est difficile d’imaginer le quotidien d'une personne avec une maladie chronique. Ce que cette personne verra, c’est la partie visible comme la prise d’un traitement, ou les rendez-vous réguliers chez des spécialistes. Mais la partie invisible, vivre au quotidien avec une maladie et l’énergie que cela demande, est plus difficile à concevoir.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5 lg:flex md:flex">
                <div className="flex lg:flex lg:my-[20px] md:flex md:my-[20px] font-arimo">
                    <img src={LittleBanner} alt="silver-spoon" className="h-2 w-10 my-auto lg:my-auto lg:w-24 md:my-auto md:h-[8px]"></img>
                    <span className="pl-2 pr-4 text-[18px] lg:pl-[20px] md:pl-[20px] lg:my-auto font-bold lg:text-[24px] md:my-auto text-emerald-800 md:text-xl">La théorie naissante des cuillères</span>
                </div>
            </section>
            <section className="mx-10 lg:mx-20 lg:my-auto font-arimo md:mx-16">
                <div className="lg:flex lg:flex-col md:flex md:flex-col">
                    <div className="mb-16 lg:mx-20 lg:my-[20px] md:m-0">
                        <p className="text-justify text-[15px] mb-5 lg:mb-[20px] lg:text-[20px] md:mb-[20px] md:text-[16px] leading-6">Comment dépenser son énergie ? Comment utiliser ses "cuillères" ? C'est en voulant expliquer à son amie cette réalité que Miserandino a utilisé pour la première fois la métaphore des cuillères.</p>
                        <img src={SpoonFull} alt="" className="mt-5 mb-12 w-full max-w-3xl mx-auto block"></img>
                        <p className="text-justify text-[15px] mb-5 lg:mb-[20px] lg:text-[20px] md:mb-[20px] md:text-[16px] leading-6">“J'essayais de trouver les bons mots. Parler des médicaments et du suivi médical est une chose, mais comment expliquer ce que ça fait réellement d'être malade ?” Christine a alors récupéré toutes les cuillères sur les tables qui l'entouraient, les a données à son amie en lui disant : "voilà, tu as le lupus".</p>
                        <img src={SpoonHalf} alt="" className="mt-5 mb-12 w-full max-w-3xl mx-auto block"></img>
                        <p className="text-justify text-[15px] mb-5 lg:mb-[20px] lg:text-[20px] md:mb-[20px] md:text-[16px] leading-6">Elle explique que chaque activité qu'une personne effectue consomme une ou plusieurs « cuillères », ou une unité d'énergie, sachant que les personnes ayant une fatigue chronique ne disposent, pour certains jours, que d'un nombre limité de cuillères. On ne se réveille pas chaque matin avec la même énergie et donc le même nombre de cuillères à dépenser. On devra faire des choix.</p>
                    </div>
                </div>
            </section>
            <section className="mb-5 lg:flex md:flex">
                <div className="flex lg:flex lg:my-[20px] md:flex md:my-[20px] font-arimo">
                    <img src={LittleBanner} alt="silver-spoon" className="h-2 w-10 my-auto lg:my-auto lg:w-24 md:my-auto md:h-[8px]"></img>
                    <span className="pl-2 pr-4 text-[18px] lg:pl-[20px] md:pl-[20px] lg:my-auto font-bold lg:text-[24px] text-emerald-800 md:text-xl">À qui peut-elle servir ?</span>
                </div>
            </section>
            <section className="mx-10 lg:mx-20 lg:my-auto font-arimo md:mx-16">
                <div className="lg:flex lg:flex-col md:flex md:flex-col">
                    <div className="lg:mx-20 lg:flex lg:justify-between md:m-0 md:flex md:justify-between">
                        <img src={EnlightSpoon} alt="" className="block mx-auto mb-10 max-w-md w-full md:w-[50%] md:h-[50%] lg:my-auto md:my-auto md:mr-[30px]"></img>
                        <div className="lg:flex lg:flex-col lg:mx-[10px] lg:my-auto">
                            <p className="text-justify text-[15px] mb-5 font-bold lg:m-0 lg:text-[20px] md:text-[16px] lg:font-bold md:font-bold leading-6">Il est important de comprendre que le nombre de cuillères n'est jamais fixe : on peut en avoir plus ou moins si on est dans une phase de poussée de la maladie.</p>
                            <p className="text-justify text-[15px] mb-5 lg:mb-[20px] lg:text-[20px] md:mb-[20px] md:text-[16px] leading-6">De même, toutes les personnes n'auront pas la même difficulté à réaliser des actes quotidiens.</p>
                            <p className="text-justify text-[15px] mb-5 lg:mb-[20px] lg:text-[20px] md:mb-[20px] md:text-[16px] leading-6">Il n'existe pas d'échelle détaillée expliquant combien coûte une cuillère et comment elles se récupèrent, car cela dépend complètement des personnes et du moment.</p>
                        </div>
                    </div>
                    <div className="mb-16 lg:mx-20 lg:my-[20px] md:m-0">
                        <p className="text-justify text-[15px] mb-5 lg:mb-[20px] lg:text-[20px] md:mb-[20px] md:text-[16px] leading-6">Depuis cette soirée, elle a utilisé la « Théorie des cuillères » pour expliquer sa vie à plusieurs personnes. C’est devenu un code entre eux pour qu’ils sachent ce qu’elle peut et ce qu’elle ne peut pas faire. Elle pense que cette explication n’est pas uniquement utile pour comprendre le lupus, mais également pour comprendre toutes les personnes vivant avec un handicap ou une maladie.</p>
                        <p className="text-justify text-[15px] mb-5 lg:mb-[20px] lg:text-[20px] md:mb-[20px] md:text-[16px] leading-6">C’est devenu une de ses maximes célèbres, lorsqu’elle leur dit qu’ils devraient se compter chanceux quand elle passe du temps avec eux, car ils ont une de ses « cuillères ».</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Theory