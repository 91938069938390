/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Header from '../components/Header';
import BeginStudy from '../components/BeginStudy';
import Footer from '../components/Footer';
import { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function App() {

  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ documentTitle: 'Page d\'accueil' });
  }, []);
  
  return (
    <div className="App">
      <Header />
      <BeginStudy />
      <Footer />
    </div>
  );
}

export default App;
