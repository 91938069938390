import React from 'react';
import Spoons from '../image/spoons.png';
import WoodenSpoon from '../image/wooden-spoon.png';
import SilverSpoon from '../image/silver-spoon.png';
import { Link } from 'react-router-dom';
import AnnieArcher from '../image/annie_archer.png';
import PierreBoulanger from '../image/pierre_boulanger.png';
import JeanPhilippeCamdessanche from '../image/jean_philippe_camdessanche.png';
import SabrinaSacconi from '../image/sabrina_sacconi.png';
import GuilhemSole from '../image/guilhem_sole.png';

function Study() {
    return (
        <div className="flex flex-col lg:min-h-full lg:min-w-full font-arimo">
            <section className="mb-24 lg:grid lg:grid-cols-2">
                <img src={Spoons} alt="spoons" className="block mx-auto w-full max-w-2xl my-10 lg:mt-36" />
                <div className="lg:pr-[120px]">
                    <h1 className="mt-20 mb-5 pl-10 pr-10 font-bold text-[28px] lg:font-bold lg:text-[32px] lg:mb-8 lg:pl-0 md:mb-5 md:font-bold md:text-[32px]">L’ÉTUDE SPOON</h1>
                    <div className="mx-10 lg:mx-0">
                        <p className="text-justify mb-4 font-bold text-lg leading-tight text-emerald-800 lg:mb-12 lg:font-bold lg:text-xl lg:text-emerald-800 lg:whitespace-pre-wrap lg:leading-8 md:mb-5 md:font-bold md:text-xl md:text-emerald-800 md:whitespace-pre-wrap md:leading-8">Quels sont les effets de la myasthénie sur la qualité de vie des patients ? Quelles sont les attentes des patients sur le contrôle de leur maladie et sur l’amélioration de leur traitement ?</p>
                        <p className="text-justify mb-2 text-[17px] lg:mb-12 lg:text-xl lg:whitespace-pre-wrap lg:leading-8 md:mb-5 md:text-xl md: whitespace-pre-wrap md:leading-8">Vous pouvez participer à l’étude SPOON en répondant à un questionnaire en ligne qui vous permettra de vous exprimer librement sur ces sujets.</p>
                        <p className="text-justify mb-2 text-[17px] lg:mb-12 lg:text-xl lg:whitespace-pre-wrap lg:leading-8 md:mb-5 md:text-xl md: whitespace-pre-wrap md:leading-8 font-bold">Si vous êtes majeur(e), que vous avez une myasthénie auto-immune pour laquelle vous avez déjà reçu un traitement, et que vous résidez en France métropolitaine ou d’Outre-Mer depuis plus d’un an, nous avons besoin de vous car votre témoignage est important pour répondre à ces questions.</p>
                        <p className="text-justify mb-2 text-[17px] lg:mb-12 lg:text-xl lg:whitespace-pre-wrap lg:leading-8 md:italic md:text-xl md: whitespace-pre-wrap md:leading-8">Cette étude en ligne est anonyme et vous prendra moins de 30 min. Vous pourrez vous interrompre en enregistrant vos réponses au fur et à mesure et y revenir plus tard, si vous le souhaitez.</p>
                    </div>
                </div>
            </section>

            <section className="flex items-center">
                <div className="bg-[#FFEAD6] h-2 w-20 rounded-br-lg"></div>
                <div>
                    <p className="mx-10 font-bold text-emerald-800 text-[18px] text-center lg:mx-[10px] lg:text-[32px] lg:font-semibold lg:text-emerald-800 md:mx-[10px] md:text-[32px] md:font-semibold md:text-emerald-800 my-0 uppercase">Découvrez le comité scientifique</p>
                </div>
                <div className="bg-[#FFEAD6] h-2 flex-1 rounded-bl-lg"></div>
            </section>
            <section className="mx-10 mb-10 mt-5 lg:flex lg:flex-col md:flex md:flex-col">
                <div className="mb-8 lg:mx-20 lg:my-[20px] md:m-0">
                    <p className="text-justify text-[15px] mb-10 lg:m-0 lg:text-[20px] md:text-[16px] leading-6">Cette étude est supervisée par un comité scientifique composé de médecins spécialistes de la myasthénie, ainsi que des patients experts du groupe d’intérêt myasthénies de l’AFM-Telethon et l’association Association francophone dédiée aux malades de la Myasthénie et à leurs familles (AMIS).</p>
                </div>
                <div className="flex flex-col space-y-14 md:space-y-5 w-full mx-auto sm:flex-row sm:flex-wrap sm:flex-1 sm:justify-center sm:items-end max-w-6xl">
                    <div className="flex flex-col items-center">
                        <div className="photo-comite">
                            <img src={AnnieArcher} alt="Annie ARCHER" className="photo-comite-img" />
                        </div>
                        <div className="flex w-full sm:w-96">
                            <img src={WoodenSpoon} alt="wooden-spoon" className="w-[80px] lg:w-[109px] lg:min-h-[75px] md:w-[109px] md:min-h-[75px]"></img>
                            <span className="mx-1 my-auto font-semibold text-[14px]">Annie ARCHER - Groupe d’Intérêt Myasthénie – AFM Téléthon</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="photo-comite">
                            <img src={PierreBoulanger} alt="Pierre BOULANGER" className="photo-comite-img no-margin-bottom" />
                        </div>
                        <div className="flex w-full sm:w-96">
                            <img src={SilverSpoon} alt="silver-spoon" className="w-[80px] lg:w-[109px] lg:min-h-[75px] md:w-[109px] md:min-h-[75px]"></img>
                            <span className="mx-1 my-auto font-semibold text-[14px]">Pierre BOULANGER - AMIS</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="photo-comite">
                            <img src={JeanPhilippeCamdessanche} alt="Jean-Philippe CAMDESSANCHE" className="photo-comite-img" />
                        </div>
                        <div className="flex w-full sm:w-96">
                            <img src={WoodenSpoon} alt="wooden-spoon" className="w-[80px] lg:w-[109px] lg:min-h-[75px] md:w-[109px] md:min-h-[75px]"></img>
                            <span className="mx-1 my-auto font-semibold text-[14px]">Pr Jean-Philippe CAMDESSANCHE - <br></br> CHU de Saint-Étienne Hôpital Nord</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="photo-comite">
                            <img src={SabrinaSacconi} alt="Sabrina SACCONI" className="photo-comite-img" />
                        </div>
                        <div className="flex w-full sm:w-96">
                            <img src={SilverSpoon} alt="silver-spoon" className="w-[80px] lg:w-[109px] lg:min-h-[75px] md:w-[109px] md:min-h-[75px]"></img>
                            <span className="mx-1 my-auto font-semibold text-[14px]">Pr Sabrina SACCONI - CHU de Nice</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="photo-comite">
                            <img src={GuilhemSole} alt="Guilhem SOLE" className="photo-comite-img" />
                        </div>
                        <div className="flex w-full sm:w-96">
                            <img src={WoodenSpoon} alt="wooden-spoon" className="w-[80px] lg:w-[109px] lg:min-h-[75px] md:w-[109px] md:min-h-[75px]"></img>
                            <span className="mx-1 my-auto font-semibold text-[14px]">Pr Guilhem SOLE - CHU de Bordeaux</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Study