/* eslint-disable react-hooks/exhaustive-deps */
import Footer from '../components/Footer';
import Header from '../components/Header';
import { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const CGU = () => {

  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ documentTitle: 'Page de CGU' });
  }, []);

  return (
    <>
      <Header />
      <div className="container mx-auto my-20 px-5 font-arimo">
        <h1 className="text-xl text-emerald-800 md:text-3xl font-bold mb-10 md:mb-16">Conditions Générales d'Utilisation de la plateforme SPOON</h1>
        <p className="cgu-paragraph">
          Les présentes Conditions Générales d’Utilisation (ci-après les « CGU ») ont pour objet de définir vos droits et obligations dans le cadre de votre participation à l’étude dénommée Spoon, en tant qu’utilisateurs (ci-après « les Utilisateurs » ou « l’Utilisateur ») de la plateforme Spoon (ci-après la « Plateforme »), de définir les conditions générales d’accès et d’utilisation de la Plateforme par tout Utilisateur et de rappeler les dispositions légales et règlementaires en vigueur. <br /><br />

          Cette étude est mise en œuvre par la société UCB Pharma SA (« UCB ») inscrite au répertoire SIREN sous le numéro 562 079 046, dont le siège social est situé au 420 rue Etienne d’Orves, 92700 Colombes. UCB pourra modifier à tout moment les présentes Conditions Générales d'Utilisation, après information de l’Utilisateur par la mise en ligne de la nouvelle version des Conditions Générales d’Utilisation et acceptation préalable de l’Utilisateur pour utiliser la Plateforme.<br /><br />

          Cette Plateforme est gérée par la société SKEZI.<br /><br />

          La Plateforme est réservée aux Utilisateurs sélectionnés pour participer à l’étude dénommée Spoon.
        </p>

        <h2 className="cgu-title">1. Objet</h2>
        <p className="cgu-paragraph">
          La Plateforme est une solution SaaS permettant le recueil de données de patients en vue de la réalisation d’une étude de santé nécessitant le recueil de données patients, dans le cadre du parcours de soin et/ou à des fins de recherche scientifique.<br /><br />

          L’objectif de cette Plateforme est de permettre la mise en œuvre d’une enquête, constituée de participants atteint de myasthénie généralisée, afin de répondre à des questions de recherche dans le domaine thérapeutique des maladies chroniques.<br /><br />

          La Plateforme est à l’usage personnel des Utilisateurs et ne peut être utilisée pour aucune autre finalité.<br /><br />

          Cette étude Spoon a pour vocation de répondre à plusieurs objectifs scientifiques, qui sont de comprendre l’impact de la myasthénie sur la qualité de vie des participants et d’identifier leurs attentes en matière de contrôle de la maladie et d’amélioration des traitements.<br /><br />

          Les présentes CGU ont pour objet de déterminer les règles et les modalités d'utilisation de la Plateforme.  
        </p>

        <h2 className="cgu-title">2. Conditions d’accès et d’utilisation de la Plateforme</h2>
        <p className="cgu-paragraph">
          La Plateforme est accessible via le lien https://spoon.skezi.eu.<br /><br />

          Pour accéder à la Plateforme, l’Utilisateur répond directement au questionnaire dès lors qu’il a validé les présentes Conditions et qu’il a pris connaissance de la notice d’information.<br /><br />

          L’Utilisateur ne doit pas créer de compte personnel ni renseigner de données personnelles pour accéder au questionnaire.<br /><br />

          L’Utilisateur garantit que les données qu'il renseigne sont exactes et conformes à la réalité. UCB se réserve le droit de suspendre ou fermer le compte de l’Utilisateur dans la situation où des informations seraient erronées, incomplètes, obsolètes ou fournies en fraude de droits des tiers.<br /><br />

          Une notice d’information relative à l’étude est portée à la connaissance de l’Utilisateur avant sa participation à l’étude. Si l’Utilisateur n’en prend pas connaissance, il ne sera pas autorisé à participer à l’étude.<br /><br />

          L’Utilisateur a la possibilité d’imprimer à tout moment le questionnaire, qu’il soit vide ou complété.<br /><br />

          L’Utilisateur a également la possibilité de compléter en plusieurs fois le questionnaire via un système d’envoi de code sur son adresse e-mail. L’Utilisateur dans un premier temps renseigne son adresse e-mail. Un code unique lui est envoyé. Il faudra alors saisir ce code lors de la prochaine visite dans la partie « récupérer mon questionnaire » et l’Utilisateur pourra continuer à renseigner son questionnaire. L’adresse e-mail de l’Utilisateur ne sera pas utilisée ni conservée.<br /><br />

          La plateforme ne comprend pas de compte Utilisateur.<br /><br />

          Toutes les données relatives aux réponses volontairement renseignées par tout Utilisateur sont disponibles sur la Plateforme.<br /><br />

          La Plateforme est accessible sur ordinateur, téléphone mobile ou tablette.<br /><br />

          Dans le cadre de la mesure de l’audience sur la Plateforme, UCB est amené à utiliser des traceurs destinés à la seule mesure de l’audience de la Plateforme (mesure des performances, détection de problèmes de navigation, optimisation des performances techniques ou de son ergonomie, estimation de la puissance des serveurs nécessaires, analyse des contenus consulté), pour son compte exclusif. Les traceurs servent exclusivement à produire des données statistiques.<br /><br />

          Les traceurs ne sauraient conduire à un recoupement des données avec d’autres traitements ou à ce que les données soient transmises à des tiers, ni à permettre le suivi global de la navigation de la personne utilisant différentes applications ou naviguant sur différents sites web. Les traceurs utilisés sont les suivants :<br /><br />
        </p>
        <table className="cookies-table">
          <tbody>
            <tr>
              <td className="highlight">_pk_id</td>
              <td>Matomo</td>
              <td>Statistique</td>
              <td>Utilisé pour stocker quelques détails sur l'utilisateur tels que l'identifiant unique du visiteur.</td>
              <td>13 mois</td>
            </tr>
            <tr>
              <td className="highlight">_pk_ses</td>
              <td>Matomo</td>
              <td>Statistique</td>
              <td>Cookies de courte durée utilisées pour stocker temporairement des données pour la visite.</td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td className="highlight">_pk_ref</td>
              <td>Matomo</td>
              <td>Statistique</td>
              <td>Utilisé pour stocker les informations d'attribution, le référent initialement utilisé pour visiter le site Web.</td>
              <td>6 mois</td>
            </tr>
          </tbody>
        </table>

        <h2 className="cgu-title">3. Propriété intellectuelle</h2>
        <p className="cgu-paragraph">
          L’ensemble des éléments constitutifs de la Plateforme (marques, logos, noms commerciaux, noms de domaine, données, métadonnées, dessins, images, photographies, audio ou vidéo, textes, dessins, programmes informatiques, scripts, agencement du site internet, etc.), sans que cette liste soit exhaustive, sont protégés par des droits de propriété intellectuelle.<br /><br />

          Toute reproduction, représentation, modification, publication, adaptation, téléchargement, transmission de tout ou partie des éléments de la Plateforme, par quelque moyen que ce soit est interdit, sauf autorisation écrite et préalable.<br /><br />

          Tout accès, modification, ajout, suppression qui porte sur le système de traitement automatisé de données et qui modifie les conditions de publication ou la politique éditoriale est interdit.<br /><br />

          Les droits de propriété intellectuelle d’UCB restent la propriété exclusive de celui-ci ou, en cas de contenu fourni par des tiers, la propriété de ces derniers si UCB a obtenu les autorisations nécessaires pour les utiliser sur sa Plateforme.<br /><br />

          Toute représentation ou exploitation totale ou partielle de tout ou partie des éléments de la Plateforme par quelque personne (physique ou morale) que ce soit, sans l'autorisation expresse de SKEZI et d’UCB, est interdite et constituerait une contrefaçon.
        </p>

        <h2 className="cgu-title">4. Responsabilité</h2>
        <p className="cgu-paragraph">
          L’Utilisateur reconnaît être informé que l’étude proposée se déroule à distance et de façon dématérialisée. L’Utilisateur reconnaît disposer de la compétence, des matériels et moyens nécessaires pour accéder à la Plateforme.<br /><br />

          L’Utilisateur s’engage à utiliser la Plateforme conformément à la finalité décrite dans les présentes Conditions Générales d'Utilisation et aux règlementations en vigueur, et à indiquer uniquement des informations ou contenus conformes à la réalité. L’Utilisateur s’engage à ne pas perturber l’usage normal de la Plateforme. Le manquement à ces obligations ainsi définies peut engendrer la suspension de son accès à la Plateforme et des poursuites judiciaires.<br /><br />

          En aucun cas, UCB ne saurait être tenue responsable au titre de la responsabilité médicale ou du fait des soins et/ou conseils délivrés dans le cadre du parcours de soin de l’Utilisateur.
        </p>

        <h2 className="cgu-title">5. Disponibilité</h2>
        <p className="cgu-paragraph">
          UCB prend les mesures nécessaires au maintien de la continuité et de la qualité de l’étude proposée sur la Plateforme. L’Utilisateur est informé que la Plateforme est fournie sans aucune garantie de disponibilité et de régularité.<br /><br />

          UCB s’efforce de rendre la Plateforme accessible 24 heures sur 24, 7 jours sur 7, sauf en cas de force majeure ou d’un événement hors du contrôle d’UCB et sous réserve des périodes de maintenance, pannes éventuelles et aléas techniques internes ou extérieurs à UCB. Aucune responsabilité ne saurait être recherchée à l’encontre d’UCB en cas de perte de disponibilité ou d’accès à la Plateforme, quelle qu’en soit la durée ou la fréquence.<br /><br />

          UCB se réserve la possibilité de modifier ou d’interrompre temporairement ou de façon permanente, toute ou partie de la Plateforme, sans préavis ni indemnité.
        </p>

        <h2 className="cgu-title">6. Droit applicable</h2>
        <p className="cgu-paragraph">
          Tout litige en relation avec l'utilisation de la Plateforme est soumis au droit français et relève de la compétence exclusive des juridictions françaises.
        </p>

        <h2 className="cgu-title">7. Modifications des conditions générales</h2>
        <p className="cgu-paragraph">
          Les Conditions Générales peuvent être modifiées à tout moment par UCB.<br /><br />

          Les modifications entreront en vigueur à compter de leur mise en ligne sur l’Application. 
        </p>

        <p className="cgu-paragraph mt-14 sm:mt-20">
          <u>Date de la dernière mise à jour</u> : 08/09/2022 
        </p>
      </div>
      <Footer />
    </>
  );
}

export default CGU;