import React from 'react';

function TheStudy() {
    return (
        <div className="font-arimo flex flex-col space-y-20 py-20 lg:space-y-0 lg:px-0 text-justify lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-20">
            <div className="flex flex-col">
                <div className="flex items-center space-x-4 sm:space-x-6 mb-3 md:mb-5 lg:mb-7">
                    <div className="bg-[#FFEAD6] w-16 sm:w-32 h-2 rounded-br-full flex-none" />
                    <span className="font-bold pr-5 text-xl sm:text-2xl text-emerald-800">Quels sont les objectifs de l’étude ? </span>
                </div>
                <p className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">L’objectif de cette étude est de comprendre l'impact de la myasthénie généralisée et de sa prise en charge sur votre vie, et d’identifier vos attentes en matière de contrôle de la maladie et d’amélioration des traitements.</p>
            </div>
            <div className="flex flex-col">
                <div className="flex items-center space-x-4 sm:space-x-6 mb-3 md:mb-5 lg:mb-7 lg:justify-end">
                    <div className="bg-[#FFEAD6] w-16 sm:w-32 h-2 rounded-br-full flex-none lg:rounded-br-none lg:rounded-bl-full lg:order-1" />
                    <span className="font-bold pr-5 text-xl sm:text-2xl text-emerald-800">Qui peut participer ?</span>
                </div>
                <p className="mb-[18px] leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Vous pouvez participer à cette étude si :</p>
                <ul className="list-disc pl-[40px]">
                    <li className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Vous avez 18 ans ou plus;</li>
                    <li className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Vous avez une myasthénie auto-immune qui a été diagnostiquée par un médecin;</li>
                    <li className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Votre myasthénie a déjà touché une autre partie du corps que vos yeux;</li>
                    <li className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Vous avez déjà reçu un traitement pour votre myasthénie;</li>
                    <li className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Vous résidez en France depuis plus d'un an.</li>
                </ul>
            </div>
            <div className="flex flex-col">
                <div className="flex items-center space-x-4 sm:space-x-6 mb-3 md:mb-5 lg:mb-7">
                    <div className="bg-[#FFEAD6] w-16 sm:w-32 h-2 rounded-br-full flex-none" />
                    <span className="font-bold pr-5 text-xl sm:text-2xl text-emerald-800">En quoi consiste l’étude ? </span>
                </div>
                <p className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Vous allez remplir un questionnaire dans lequel vous pourrez vous exprimer librement sur vos attentes par rapport à votre maladie et à votre prise en charge. Nous allons également vous poser des questions sur l’impact de votre myasthénie sur votre vie. Enfin, il y aura quelques questions sur vous et votre myasthénie. </p>
                <p className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Vous n’avez pas à créer de compte, ni à renseigner de données identifiantes. Nous estimons que cela vous prendra moins de 30 minutes.</p>
                <p className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Vous pourrez compléter le questionnaire en plusieurs fois. Un code à usage unique vous sera alors envoyé par e-mail pour vous permettre de reprendre le questionnaire là où vous l’avez quitté. Votre adresse e-mail ne sera pas enregistrée.</p>
            </div>
            <div className="flex flex-col">
                <div className="flex items-center space-x-4 sm:space-x-6 mb-3 md:mb-5 lg:mb-7 lg:justify-end">
                    <div className="bg-[#FFEAD6] w-16 sm:w-32 h-2 rounded-br-full flex-none lg:rounded-br-none lg:rounded-bl-full lg:order-1" />
                    <span className="font-bold pr-5 text-xl sm:text-2xl text-emerald-800">Que dois-je savoir avant de participer ? </span>
                </div>
                <p className="mb-[18px] leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Il y a plusieurs choses importantes à savoir :</p>
                <ul className="list-disc pl-[40px]">
                    <li className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Aucune donnée personnelle permettant directement de vous identifier ne sera collectée;</li>
                    <li className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Votre participation est volontaire et vous pouvez vous retirer de l'étude en tout temps;</li>
                    <li className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Vous pouvez contacter le responsable de l'étude pour lui faire part de vos questions et commentaires. Nous serons heureux de vous fournir de plus amples informations;</li>
                    <li className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Toutes les données collectées seront sauvegardées sur un serveur sécurisé et effacées après 2 ans. Les résultats de l'étude seront publiés dans une revue scientifique.</li>
                    <li className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Vous pourrez télécharger/imprimer le questionnaire pour votre usage personnel ou pour le partager à votre convenance avec votre médecin.</li>
                </ul>
            </div>
            <div className="flex flex-col lg:col-span-2 lg:text-center lg:mx-auto">
                <div className="flex items-center space-x-4 sm:space-x-6 mb-3 md:mb-5 lg:mb-7">
                    <div className="bg-[#FFEAD6] w-16 sm:w-32 h-2 rounded-br-full flex-none" />
                    <span className="font-bold pr-5 text-xl sm:text-2xl text-emerald-800">Comment puis-je être informé(e) des résultats de l'étude ? </span>
                    <div className="hidden lg:block bg-[#FFEAD6] w-32 h-2 rounded-bl-full flex-none" />
                </div>
                <p className="leading-6 text-md sm:text-lg px-5 md:px-7 lg:px-12">Une fois l'analyse des données terminée, un résumé sera disponible sur ce site Web.</p>
            </div>
        </div>
    )
}

export default TheStudy