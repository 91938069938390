/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Header from '../components/Header'
import TheStudy from '../components/TheStudy'
import Footer from '../components/Footer'
import { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function Study() {

    const { trackPageView } = useMatomo();
  
    useEffect(() => {
      trackPageView({ documentTitle: 'Page étude Spoon' });
    }, []);

    return (
        <div>
            <Header />
            <TheStudy />
            <Footer />
        </div>
    )
}

export default Study