import React from 'react';
import surveyData from '../first.json';

import { Model, StylesManager, Survey } from 'survey-react';
import 'survey-core/defaultV2.min.css';
import './customCssSurvey.css';

StylesManager.applyTheme('defaultV2')

function MgAdl() {
    const survey = new Model(surveyData);

    return (
        <>
            <div className="min-h-screen bg-[#FFEAD6] relative font-arimo">
                <div className="flex justify-between lg:flex lg:justify-between md:flex md:justify-between">
                    <div className='pt-2.5 lg:pt-2.5 md:pt-2.5'>
                        <div className="flex h-12 w-[130px] rounded-br-[35px] lg:flex lg:w-[480px] lg:h-20 lg:rounded-br-[85px] bg-emerald-800 md:w-72 md:flex md:h-20 md:rounded-br-[85px]"></div>
                    </div>
                  
                </div>
                <div className="survey">
                    <Survey model={survey} />
                </div>
            </div>
        </>
    )
}

export default MgAdl;